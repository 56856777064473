import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <h1>Rajam Florals</h1>
      <p>Blossoms Beyond Beauty</p>

      <div className="image-gallery">
        <div className="image-item">
          <img src="/images/flower1.jpeg" alt="Flower 1" />
          <p>Table Flowers</p>
        </div>
        <div className="image-item">
          <img src="/images/flower2.jpeg" alt="Flower 2" />
          <p>Tulips</p>
        </div>
        <div className="image-item">
          <img src="/images/flower3.jpeg" alt="Flower 3" />
          <p>Lilies</p>
        </div>
      
        <div className="image-item">
          <img src="/images/flower4.jpeg" alt="Flower 4" />
          <p>Wedding Flower</p>
          </div>
          <div className="image-item">
          <img src="/images/dom-flower3.JPG" alt="Flower 4" />
          <p>Wedding Flower</p>
          </div>
          <div className="image-item">
          <img src="/images/dom-flower2.JPG" alt="Flower 6" />
          <p>Wedding Flower</p>
          </div>
          <div className="image-item">
          <img src="/images/dom-flower1.JPG" alt="Flower 7" />
          <p>Wedding Flower</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
