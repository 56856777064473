import React from 'react';

function Contact() {
    return (
        <div>
          <h1>Contact Us</h1>
          <p>Contact us by email or call.</p>
        </div>
      );
}

export default Contact;
