import React from 'react';

function Shop() {
  return (
    <div>
      <h1>Shop Flowers</h1>
      <p>Browse our selection of roses, tulips, lilies, and more.</p>
    </div>
  );
}

export default Shop;
